import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { initializeAudioContext, playChannel, playSample } from './playMultichannel.js';

// from https://www.cgtrader.com/items/2234313/download-page
import KRK_classic5 from 'url:./assets/KRK_classic5.glb'; // Changed file extension to .glb for compatibility

import helvetica_font from 'url:./assets/fonts/helvetiker_regular.typeface.json';
import hardwoodfloor from 'url:./assets/textures/hardwoodfloor.jpg';

console.log(helvetica_font);

RectAreaLightUniformsLib.init();

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

// Controls for rotation
const controls = new OrbitControls(camera, renderer.domElement);

// Lighting
const ambientLight = new THREE.AmbientLight(0xffffff); // Changed to white light for a brighter scene
scene.add(ambientLight);
const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // Increased intensity for a brighter scene
scene.add(directionalLight);

// Floor (Living Room Floor) with RectAreaLight reflection
const floorTexture = new THREE.TextureLoader().load(hardwoodfloor);
floorTexture.wrapS = THREE.RepeatWrapping;
floorTexture.wrapT = THREE.RepeatWrapping;
floorTexture.repeat.set(5, 5); // Scaled up the texture by 50%
const floorGeometry = new THREE.PlaneGeometry(10, 10);
// Updated material to MeshStandardMaterial for better light reflection including RectAreaLight
const floorMaterial = new THREE.MeshStandardMaterial({
  map: floorTexture,
  roughness: 0.3, // Adjusted for a slightly rough surface that can still reflect light
  metalness: 0.01, // Small metalness value to aid in light reflection
});
const floor = new THREE.Mesh(floorGeometry, floorMaterial);
floor.rotation.x = -Math.PI / 2;
scene.add(floor);

// Menu for audio samples on the floor, centered and arranged bottom to top (rotated 180 degrees) with rounded buttons
const audioSamples = ['5.1 Surround Test', 'Forest', 'City', 'Jet flyby', 'Helicopter', 'String quartet', 'Jazz'];

const menuGeometry = new RoundedBoxGeometry(2, 0.2, 0.5, 2, 0.1); // Made longer in the x direction, height set to 0.1, and rounded
const glassMaterial = new THREE.MeshPhysicalMaterial({
  color: 0x00008b, // Darker blue
  metalness: 0,
  roughness: 0,
  transmission: 1, // Glass material
  transparent: true,
  opacity: 0.5,
  reflectivity: 1
});
audioSamples.forEach((sample, index) => {
  const menuMesh = new THREE.Mesh(menuGeometry, glassMaterial);
  menuMesh.position.set(0, 0.01, -2.5 + index); // Centered and slightly above the floor to avoid z-fighting, arranged bottom to top
  menuMesh.userData = { type: 'sample', name: sample };
  scene.add(menuMesh);

  // Adding text labels centered
  new FontLoader().load(helvetica_font, function (font) {
    const textGeometry = new TextGeometry(sample, {
      font: font,
      size: 0.15,
      height: 0.01,
    });
    textGeometry.computeBoundingBox();
    const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
    const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const textMesh = new THREE.Mesh(textGeometry, textMaterial);
    textMesh.position.set(-textWidth / 2, 0.11, -2.45 + index); // Centered text by adjusting position based on text width
    textMesh.rotation.x = -Math.PI / 2;
    scene.add(textMesh);
  });
});
// Walls
const wallMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: false, opacity: 0.9 });
const wallGeometry = new THREE.BoxGeometry(10, 3.5, 0.1);
const frontWall = new THREE.Mesh(wallGeometry, wallMaterial);
frontWall.position.set(0, 1.75, -5); // Adjusted to make the bottom of the wall at 0 y
scene.add(frontWall);
const sideWallGeometry = new THREE.BoxGeometry(0.1, 3.5, 10);
const leftWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
leftWall.position.set(-5, 1.75, 0); // Adjusted to make the bottom of the wall at 0 y
scene.add(leftWall);
const rightWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
rightWall.position.set(5, 1.75, 0); // Adjusted to make the bottom of the wall at 0 y
scene.add(rightWall);

// Backlit sign with lights on the back surface, letters made transparent for debugging
const signMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: false, opacity: 0.1 });
new FontLoader().load(helvetica_font, function (font) {
  const signGeometry = new TextGeometry('Surround Sound Test', {
    font: font,
    size: 0.5,
    height: 0.05,
  });
  signGeometry.computeBoundingBox(); // Ensure boundingBox is computed before use
  const signWidth = signGeometry.boundingBox.max.x - signGeometry.boundingBox.min.x;
  const sign = new THREE.Mesh(signGeometry, signMaterial);
  sign.position.set(-signWidth / 2, 2.3, -4.75); // Centered along the x-axis and moved 0.2 units from the wall
  scene.add(sign);

  // Adjusted to make the RectAreaLight visible by adding a physical object to simulate the light effect
  const rectLight = new THREE.RectAreaLight(0xffffff, 5, signWidth + 0.8, 1);
  rectLight.position.set(0, 2.5, -5+0.2); // Positioned slightly in front of the sign for visibility
  rectLight.lookAt(0,2.5,0);
  scene.add(rectLight);

  // Assuming rectLight is your RectAreaLight
  const rectLightHelper = new RectAreaLightHelper(rectLight);
  scene.add(rectLightHelper);

  // Adding a physical representation for the RectAreaLight to make it visible
  /*
  const lightMeshMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.3 });
  const lightMeshGeometry = new THREE.PlaneGeometry(signWidth + 0.8, 1);
  const lightMesh = new THREE.Mesh(lightMeshGeometry, lightMeshMaterial);
  lightMesh.position.copy(rectLight.position); // Positioned to match the RectAreaLight
  scene.add(lightMesh);
  */
});

// Load speaker models, make them smaller by 50%, lift them 1 foot off the floor, and rotate them towards the center of the floor
const loader = new GLTFLoader();
const height = 1; // Height set to 1 foot off the floor
const speakerPositions = [
  { name: "Front Left", position: [-2.5, height, -4], rotation: [0, Math.PI / 6, 0] },
  { name: "Front Right", position: [2.5, height, -4], rotation: [0, -Math.PI / 6, 0] },
  { name: "Center", position: [0, height, -4.5], rotation: [0, 0, 0] },
  { name: "Subwoofer", position: [-4.5, 0, -4.5], rotation: [0, 0, 0] }, // Subwoofer remains on the floor
  { name: "Surround Left", position: [-4, height, 2], rotation: [0, Math.PI / 2 , 0] },
  { name: "Surround Right", position: [4, height, 2], rotation: [0, -Math.PI / 2, 0] }
];
// Load all speakers except the subwoofer
speakerPositions.filter(speaker => speaker.name !== "Subwoofer").forEach(speaker => {
  loader.load(KRK_classic5, function(gltf) {
    gltf.scene.position.set(...speaker.position);
    gltf.scene.rotation.set(...speaker.rotation);
    const speakerScale = 0.4;
    gltf.scene.scale.set(speakerScale, speakerScale, speakerScale); // Scale down by 50%
    gltf.scene.traverse(function(child) {
      if (child.isMesh) {
        child.userData.name = speaker.name; // Assign name to each speaker for identification
        child.userData.position = speaker.position; // Store the speaker's position in userData for accurate sound wave positioning
      }
    });
    scene.add(gltf.scene);
  }, undefined, function(error) {
    console.error(error);
  });
});

// Adding explanatory label above the left surround speaker and an arrow pointing to the speaker
new FontLoader().load(helvetica_font, function (font) {
  const labelGeometry = new TextGeometry('Click to play', {
    font: font,
    size: 0.2,
    height: 0.01,
  });
  const labelMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
  const labelMesh = new THREE.Mesh(labelGeometry, labelMaterial);
  labelMesh.position.set(-4.3, height + 1.2, 2); // Positioned above the left surround speaker
  labelMesh.name = "clickToPlayLabel"; // Naming the labelMesh for retrieval with getObjectByName
  scene.add(labelMesh);

  // Create an arrow pointing from the label to the speaker with a box for the shaft and a triangle for the head
  const arrowMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

  // Creating the arrow head as a triangle with thickness
  const headPoints = [];
  headPoints.push(new THREE.Vector2(0, 0)); // Base left
  headPoints.push(new THREE.Vector2(0.1, 0.05)); // Tip
  headPoints.push(new THREE.Vector2(0, 0.1)); // Base right
  headPoints.push(new THREE.Vector2(0, 0)); // Back to base left to close the shape

  const headShape = new THREE.Shape(headPoints);
  const extrudeSettings = {
    steps: 2,
    depth: 0.01, // Set the thickness to match the text
    bevelEnabled: false, // No bevel for a flat arrow head
  };
  const headGeometry = new THREE.ExtrudeGeometry(headShape, extrudeSettings);
  const headMesh = new THREE.Mesh(headGeometry, arrowMaterial);
  headMesh.position.set(-4, height + 1.1, 2); // Position adjusted to connect with the shaft
  headMesh.rotation.z = -Math.PI / 2; // Rotate to align with the shaft
  headMesh.name = "clickToPlayArrow"; // Naming the headMesh for retrieval with getObjectByName
  scene.add(headMesh);
});


// Create a simple box for the subwoofer
const subwooferGeometry = new THREE.BoxGeometry(0.9, 0.9, 0.9); // Simple box geometry, made smaller
const subwooferMaterial = new THREE.MeshLambertMaterial({color: 0x777777}); // Lighter grey color for the subwoofer
const subwooferMesh = new THREE.Mesh(subwooferGeometry, subwooferMaterial);
subwooferMesh.position.set(-4.5, 0.45, -4.5); // Position in the front left corner and slightly adjusted to not touch the edge
subwooferMesh.userData.name = "Subwoofer"; // Assign name to the subwoofer for identification
subwooferMesh.userData.position = [-4.5, 0.45, -4.5]; // Store the subwoofer's position in userData for accurate sound wave positioning
scene.add(subwooferMesh);

// Raycaster for mouse interaction
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

// Text box for displaying speaker name or sample name
const speakerNameBox = document.createElement('div');
speakerNameBox.style.position = 'fixed';
speakerNameBox.style.bottom = '20px';
speakerNameBox.style.left = '50%';
speakerNameBox.style.transform = 'translateX(-50%)';
speakerNameBox.style.padding = '10px 20px';
speakerNameBox.style.borderRadius = '15px';
speakerNameBox.style.background = 'rgba(0, 0, 0, 0.5)';
speakerNameBox.style.color = 'white';
speakerNameBox.style.display = 'none';
speakerNameBox.style.fontSize = '20px';
speakerNameBox.style.border = '2px solid white';
document.body.appendChild(speakerNameBox);

let audioContextInitialized = false;

function onMouseClick(event) {
  // Initialize audio context on first click
  if (!audioContextInitialized) {
    initializeAudioContext();
    audioContextInitialized = true;
  }

  // Calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

  // Update the picking ray with the camera and mouse position
  raycaster.setFromCamera(mouse, camera);

  // Calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects(scene.children, true);

  for (let i = 0; i < intersects.length; i++) {
    const object = intersects[i].object;
    if (object.userData.type === 'sample') {
      speakerNameBox.innerText = `Playing sample: ${object.userData.name}`;
      speakerNameBox.style.display = 'block';
      playSample(object.userData.name); // Play the selected audio sample
      setTimeout(() => {
        speakerNameBox.innerText = '';
        speakerNameBox.style.display = 'none';
      }, 3000); // Hide the text box after 3 seconds
      break; // Stop checking after the first sample is found and interacted with
    } else if (object.userData.name) {
      speakerNameBox.innerText = `Playing: ${object.userData.name}`;
      speakerNameBox.style.display = 'block';

      // Determine the index for playChannel based on the speaker name
      const speakerIndexMap = {
        "Front Left": 0,
        "Front Right": 1,
        "Center": 2,
        "Subwoofer": 3,
        "Surround Left": 4,
        "Surround Right": 5
      };
      const channelIndex = speakerIndexMap[object.userData.name];
      if (channelIndex !== undefined) {
        playChannel(channelIndex);
        showSoundWaves(object, 0.5, new THREE.Vector3(...object.userData.position), () => {
          // Clear the text in the textbox when the wave animation finishes
          speakerNameBox.innerText = '';
          speakerNameBox.style.display = 'none';
          // Remove 'click to play' and arrow if they exist
          if (scene.getObjectByName('clickToPlayLabel')) scene.remove(scene.getObjectByName('clickToPlayLabel'));
          if (scene.getObjectByName('clickToPlayArrow')) scene.remove(scene.getObjectByName('clickToPlayArrow'));
        }); // Use the stored position for accurate sound wave positioning
      }
      break; // Stop checking after the first speaker is found and interacted with
    }
  }
}

function showSoundWaves(speaker, initialScale = 0.5, position, onAnimationComplete) {
  const waveMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff, transparent: true, opacity: 0.001 }); // Made waves even more transparent
  const waves = [];
  for (let i = 1; i <= 3; i++) { // Reduced number of waves to 3
    const waveGeometry = new THREE.SphereGeometry(i * 0.5, 32, 32); // Sphere radius halved, using a complete sphere
    const wave = new THREE.Mesh(waveGeometry, waveMaterial);
    wave.position.copy(position); // Use the passed position for wave origin
    scene.add(wave);
    waves.push(wave);
  }

  let scale = initialScale; // Starting scale is now a parameter
  function animateWaves() {
    requestAnimationFrame(animateWaves);
    waves.forEach(wave => {
      wave.scale.set(scale, scale, scale);
      // Adjust opacity dynamically based on scale to ensure waves become more transparent as they grow
      wave.material.opacity = Math.max(0.005, 0.5 - (scale / 12)); // Made waves start more transparent and adjust dynamically
    });
    scale += 0.025; // Scale increment halved
    if (scale > 3) { // Max scale adjusted for new starting scale
      waves.forEach(wave => scene.remove(wave));
      scale = initialScale; // Reset scale to initialScale instead of 1
      onAnimationComplete(); // Call the callback function when animation completes
    }
  }
  animateWaves();
}
window.addEventListener('click', onMouseClick, false);

// Camera position
camera.position.set(0, 3, 7);
camera.lookAt(0, 0, 0);

// Animation loop
export function animate() {
  requestAnimationFrame(animate);
  controls.update();
  renderer.render(scene, camera);
}

animate();

window.addEventListener( 'resize', onWindowResize, false );

function onWindowResize(){
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
}
